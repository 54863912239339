<template>
  <article>
    <Hero heading="Getting Started with Electrify America">
      <template slot="image">
        <picture>
          <source
            srcset="
              @/assets/images/GettingStarted/hero-getting-started.png,
              @/assets/images/GettingStarted/hero-getting-started-desktop@2x.png 2x
            "
            media="(min-width: 750px)"
            alt="Woman using mobile device near an Electrify America charging station"
          />
          <source
            alt="Woman using mobile device near an Electrify America charging station"
            srcset="
              @/assets/images/GettingStarted/hero-getting-started-mobile.png,
              @/assets/images/GettingStarted/hero-getting-started-mobile@2x.png 2x
            "
          />
          <img
            src="@/assets/images/GettingStarted/hero-getting-started-mobile.png"
            alt="Woman using mobile device near an Electrify America charging station"
          />
        </picture>
      </template>
    </Hero>
    <TwoColumnContentBlock heading="What is DC fast charging?" headlineDisplayClass="headline3" :maxWidth="1000">
      <p>
        <strong>Direct Current (DC) chargers</strong> quickly deliver power to your vehicle’s battery to cut down on
        charging times and get you back on the road. Often used for road trips and on-the-go charging, our DC fast
        chargers offer the fastest charging speeds available today with Hyper-Fast charging up to 350 kW.
      </p>
      <p>Electrify America is the largest open DC fast charging network in the United States.</p>
    </TwoColumnContentBlock>
    <h2 class="card-headline headline3">The basics</h2>
    <Card headlineLevel="h3" heading="How does it work?" :maxWidth="1000" headlineDisplayClass="headline4">
      <template #image>
        <img
          loading="lazy"
          v-if="isPhone"
          src="@/assets/images/GettingStarted/image-charging-station-infographic.png"
          alt="Infograph displaying charging stations, connectors, and power cabinets"
        />
        <img
          loading="lazy"
          v-else
          src="@/assets/images/GettingStarted/image-charging-station-infographic-desktop.png"
          alt="Infograph displaying charging stations, connectors, and power cabinets"
        />
      </template>

      <p>
        The Electrify America charging experience was created with you in mind. When you arrive at one of our
        <strong>charging stations</strong>, you’ll select a <strong>charger</strong>. After you plug the charger’s
        <strong>connector</strong> into your vehicle’s charging port and your payment method is verified, the charger
        will deliver power from the <strong>power cabinet</strong>.
      </p>
      <p>
        Your vehicle may take a few minutes to start charging and details about your charging session will appear on the
        charger’s screen.
      </p>
      <div class="tip">
        <img loading="lazy" src="@/assets/images/GettingStarted/icon-star.png" alt="" />
        <p>
          <strong>Tip:</strong> Though there are two connectors on select Electrify America chargers, only one connector
          can be used at a time.
        </p>
      </div>
    </Card>
    <Card
      headlineLevel="h3"
      heading="Which connector should I use?"
      :maxWidth="1000"
      headlineDisplayClass="headline4"
      :isReverse="!isPhone"
    >
      <template #image>
        <img
          loading="lazy"
          src="@/assets/images/GettingStarted/connector-types@2x.png"
          alt="Charging station portrayed with charger types CCS and CHAdeMO"
        />
      </template>
      <p>
        Our DC fast chargers include two connector types: Combined Charging System (<strong>CCS</strong>) and
        <strong>CHAdeMO</strong>. Both connectors can be found at most Electrify America charging stations and
        identified by the teal, green, and blue labels on the chargers.
      </p>
      <p>
        Our CCS connectors can charge at speeds up to 350 kW, while CHAdeMO connectors can charge up to 50 kW. Your
        vehicle’s connector type and maximum charging speed are determined by your vehicle’s manufacturer.
      </p>
      <div class="tip">
        <img loading="lazy" src="@/assets/images/GettingStarted/icon-star.png" alt="" />
        <p>
          <strong>Tip:</strong> Check with your vehicle’s manufacturer or owner’s manual to learn your electric
          vehicle’s charging capabilities.
        </p>
      </div>
    </Card>
    <div class="lede lede--last">
      <div class="column">
        <section class="l-one l-one--has-bg">
          <div class="l-one__col">
            <h2 class="headline3">What happens when I plug in?</h2>
            <p>
              First, your electric vehicle (EV) requests power (kW) from the charger. Then, the charger delivers power
              directly to your vehicle’s battery. The amount of power requested may be affected by temperature, battery
              health, and battery level.
            </p>
            <div class="tip">
              <img loading="lazy" src="@/assets/images/GettingStarted/icon-star.png" alt="" />
              <p>
                <strong>Tip:</strong> After plugging in, it may take a few minutes for your charge to start. Details
                about your charging session will appear on the charger’s screen.
              </p>
            </div>
            <img
              loading="lazy"
              v-if="isPhone"
              src="@/assets/images/GettingStarted/what-happens.png"
              alt="Dialog bubble from car states 'I need 150 kW.' Dialog bubble from charging station states 'Coming right up!'"
            />
            <img
              loading="lazy"
              v-else
              src="@/assets/images/GettingStarted/what-happens-desktop.png"
              alt="Dialog bubble from car states 'I need 150 kW.' Dialog bubble from charging station states 'Coming right up!'"
            />
          </div>
        </section>
        <div class="l-one">
          <div class="l-one__col">
            <h3 class="headline4">What happens when I charge?</h3>
            <p>
              At the beginning of a charging session, most electric vehicles send an initial State of Charge (SOC) to
              the charger. SOC indicates how “full” your vehicle’s battery is and—if sent to our chargers—can be found
              on the charger’s screen or in the Electrify America app. If available, the SOC will be updated as your
              vehicle charges, though some EVs are not capable of sending our chargers this information.
            </p>
            <p>
              Toward the end of your session, you may notice charging speeds (kW) slow down. Many vehicles “bulk charge”
              and slow their charging speed when they reach about 80%*. Because of this, we recommend unplugging your
              vehicle after bulk charging has occurred. To receive updates about your SOC and other charging
              information,
              <router-link class="link medium" :to="{ name: 'mobile-app-en' }"
                >download the Electrify America app</router-link
              >.
            </p>
          </div>
        </div>
        <section>
          <img
            loading="lazy"
            v-if="isPhone"
            src="@/assets/images/GettingStarted/charging-session-infograph@2x.webp"
            alt="Infograph depicting the energy delivered and charging speed increasing at the beginning of charge, reaching maximum delivery for the middle portion of the charge and slowing toward the end of the charge."
          />
          <img
            loading="lazy"
            v-else
            src="@/assets/images/GettingStarted/charging-session-infograph-desktop@2x.webp"
            alt="Infograph depicting the energy delivered and charging speed increasing at the beginning of charge, reaching maximum delivery for the middle portion of the charge and slowing toward the end of the charge."
          />
          <p>
            *Exact battery percentage may vary. Check with your vehicle’s manufacturer to learn when bulk charge occurs
            for your vehicle.
          </p>
        </section>
      </div>
    </div>
    <HeroWithContentOverlay :overlayBG="false" :darkBG="false" fullWidthMobile>
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/GettingStarted/charging-station-hyper-fast-desktop.png,
              @/assets/images/GettingStarted/charging-station-hyper-fast-desktop@2x.png 2x
            "
            media="(min-width: 850px)"
          />
          <source srcset="@/assets/images/WhatToExpect/charging-station-hyper-fast.png" />
          <img
            loading="lazy"
            src="@/assets/images/WhatToExpect/charging-station-hyper-fast.png"
            alt="Hyper fast charging station HMI display with mobile pay instructions, station ID, and Customer Assistance number 1-833-632-2778"
          />
        </picture>
      </template>
      <router-link class="button-link" :to="{ name: 'charging-your-ev-en' }">
        How to<span class="sr-only">charge at Electrify America stations</span><arrow class="arrow"></arrow>
      </router-link>
      <h2>How to charge at Electrify America stations</h2>
      <p>
        Start a charge at one of our stations, with our app, or from your vehicle using Apple CarPlay or Android Auto.
      </p>
    </HeroWithContentOverlay>
  </article>
</template>

<script>
import Arrow from '@/assets/images/Global/arrow_dark.svg';
import Card from '@/components/Card/Card.vue';
import Hero from '@/components/Hero/Hero.vue';
import HeroWithContentOverlay from '@/components/HeroWithContentOverlay/HeroWithContentOverlay.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'getting-started',
  components: { Arrow, Card, Hero, HeroWithContentOverlay, TwoColumnContentBlock },
  metaInfo: {
    title: `How to Use Electrify America's EV Charging Stations | Electrify America`,
    meta: [
      {
        name: 'description',
        content: `The Electrify America charging experience was created with you in mind. We've made EV charging fast and easy. Learn how it works here.`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/getting-started/' }],
  },
  computed: {
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>

<style lang="scss" scoped>
h2,
h3 {
  margin: 0;
}

.lede {
  max-width: 710px;
  &--last {
    .l-one {
      padding-top: 40px;
      .l-one__col {
        padding-left: 0;
        padding-right: 0;
      }
    }
    img:last-of-type {
      margin-top: 24px;
      margin-bottom: 24px;
    }
    section {
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h3 {
        width: 100%;
      }
    }
    section:first-of-type {
      margin-bottom: 2.5rem;
      background-image: linear-gradient(to right, #fbfbff, #f2f2f8);
      margin: 0 -20px;
      padding: 40px 20px;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
          width: 100%;
        }
      }
      img {
        margin: 0 auto;
        margin-top: 24px;
      }
      .tip {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        img {
          width: 25px;
          height: 25px;
          order: 1;
          margin: 0;
        }
        p {
          margin: 0;
          margin-left: 15px;
          order: 2;
        }
      }
    }
    section:last-of-type {
      img {
        max-width: 100%;
      }
      p {
        margin-bottom: 96px;
      }
    }

    @media (min-width: 750px) {
      .column {
        width: 100%;
        section:first-of-type {
          padding: 137px 20px;
          div {
            max-width: 710px;
            margin: 0 auto;
            img {
              height: auto;
              width: auto;
            }
          }
        }
        .l-one {
          padding: 112px 20px 32px;
          margin: 0 auto;
        }
        section:last-of-type {
          max-width: 955px;
          margin: 0 auto;
          img {
            margin-top: 0;
            margin-bottom: 48px;
          }
          p {
            max-width: 710px;
            margin: 0 auto;
            margin-bottom: 112px;
          }
        }
      }
      img:last-of-type {
        margin-top: 24px;
        margin-bottom: 96px;
      }
      max-width: 100%;
    }
  }
  @media (max-width: 750px) {
    padding: 0 20px;
  }
}
.card-headline {
  max-width: 1000px;
  margin: auto;
  margin-top: 40px;
  @media (max-width: 750px) {
    padding: 0 20px;
  }
}
.hero-with-overlay {
  margin: 112px auto;
  width: 90%;
  @media (max-width: 750px) {
    margin: 56px auto;
    max-height: 382px;
  }

  h2 {
    margin: 16px 0;
    font-size: 32px;
    line-height: 38px;
    margin-top: 0;
    font-weight: 400;

    @media (min-width: 750px) {
      font-size: 48px;
      line-height: 50px;
    }
  }
  p {
    margin: 16px 0;
    @media (max-width: 750px) {
      padding-right: 20px;
      margin-bottom: 0;
    }
  }
  ::v-deep &__image-container {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
